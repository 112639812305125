<script setup lang="ts">
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';

  import mCheckIcon from '@/assets/svg/m-check.svg';

  interface IProps {
    rating?: number;
    loading?: boolean;
  }

  const props = withDefaults(defineProps<IProps>(), {
    rating: 0,
  });
  const emits = defineEmits<{ change: [number] }>();

  const hoverRating = ref(0);

  const setHover = (index: number) => (hoverRating.value = index);
  const resetHover = () => (hoverRating.value = 0);
  const setRating = (index: number) => {
    toast('Оценка поставлена!', {
      autoClose: 1000,
      theme: 'dark',
      icon: mCheckIcon,
      type: 'success',
      transition: 'bounce',
      hideProgressBar: true,
      closeOnClick: false,
      dangerouslyHTMLString: true,
    });
    emits('change', index);
  };
</script>

<template>
  <div class="vote-rating" @mouseleave="resetHover">
    <div
      v-for="index in 10"
      :key="index"
      class="vote-rating__item"
      @mouseover="setHover(index)"
      @click="setRating(index)"
    >
      <div :class="['vote-rating__icon', { filled: index <= hoverRating, active: index <= props.rating }]">
        <icon-vote-star fill="currentFill" />
      </div>
      <span class="vote-rating__label">{{ index }}</span>
    </div>
    <v-loader-card v-if="props.loading" class="vote-rating__loader" radius="20px" />
  </div>
</template>

<style scoped lang="scss">
  .vote-rating {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 23px;
    padding: 16px 24px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      cursor: pointer;
      color: $main_grey_mid;
      svg {
        width: 31px;
        height: 31px;
      }
      &.filled {
        color: $main_yellow_light;
      }
      &.active {
        color: $main_yellow_light;
        fill: $main_yellow_light;
      }
    }
    &__label {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      color: $main_grey_dark;
    }
    &__loader {
      position: absolute;
      inset: 0;
      background-color: #f0f8ff12;
      backdrop-filter: blur(3px);
    }
    @media (max-width: $tablet) {
      padding: 8px 16px;
      gap: 6px;
      &__icon {
        svg {
          width: 24px;
          height: 24px;
        }
      }
      &__label {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
</style>
